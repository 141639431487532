<template>
  <v-list
    flex="1"
    density="compact"
    :pdt="rule === 'panel' ? `` : `md`"
    :pdr="rule === 'panel' ? `` : `2xs`"
    :pdb="rule === 'panel' ? `` : `md`"
    :pdl="rule === 'panel' ? `` : `2xs`"
    aria-label="Service Vertical Menus"
  >
    <template v-for="(item, index) in menus" :key="`${menus.length}-${index}`">
      <v-list-subheader v-if="item.type === 'subheader'" pd="md-y">
        <strong color="xl">{{ item.title }}</strong>
      </v-list-subheader>
      <hr v-else-if="item.type === 'divider'" class="hr" mg="sm-y" bg="5xs" aria-label="Option Split Line" aria-hidden="true">
      <p v-else-if="item.type === 'block'" pd="xs" />
      <v-btn
        v-else
        :to="item.path"
        :title="item.title"
        :aria-label="item.title"
        :aria-selected="item.path === $route.path ? true : false"
        @click="item.event ? item.event($event, value) : null"
        variant="text"
        role="option"
        radius="max"
        halign="start"
        extent="w-100"
        block
      >
        <v-icon
          :icon="item.icon"
          mgr="xs"
          mgb="4xs-neg"
        />
        <span :color="item.color" fsize="md">
          {{ item.title }}
        </span>
      </v-btn>
    </template>
  </v-list>
</template>

<script lang="ts">
export default {
  props: {
    rule: {
      type: String,
      default () {
        return ''
      }
    } as any,
    menus: {
      type: Array,
      required: true,
      default () {
        return []
      }
    } as any,
    value: {
      type: Object,
      default () {
        return {}
      }
    }
  }
}
</script>